import React,{useState} from 'react'
import { Container,Form,Col,Button } from 'react-bootstrap'
import {useNavigate } from 'react-router-dom';
import { loginDetailSave } from '../Redux/Action/UserDetail';
import { useDispatch } from 'react-redux';
import { emptyProperty, houseDataEmpty } from '../Redux/Action/PropertyAction';
import { emptyTodayLogs } from '../Redux/Action/LogsAction';
import { emptySchedule } from '../Redux/Action/ScheduleAction';
import { emptyAutoCut } from '../Redux/Action/AutoCut';
import { notifyFailed } from '../utlity/Notification';
import { Toaster } from 'react-hot-toast';
import { serverUrl } from '../../ApiUri';
import axios from 'axios';

export const loginUserPhone = async (email, password) => {
  const number = email;
  const response = await axios
    .post(`${serverUrl.main}/v2/auth/login`, {
      mobile: number,
      password,
      deviceToken: "",
    })
    .then(
      result => {
        if (result.status === 200) {
          const {data} = result.data;
      

          return {success: true, message: 'Sign in successful',data:data};
        } else {
          return {success: false, message: 'Authentication Error'};
        }
      },
      error => {
        let message = error.message;
        if (error.response && error.response.data) {
          message = error.response.data.message;
        }
        return {success: false, message: message};
      },
    )
    .catch(() => {
      return {success: false, message: 'Authentication Error'};
    });
  return response;
};

export default function Login() {
    const [validated, setValidated] = useState(false);
    const navigate = useNavigate();
    let dispatch = useDispatch()

    const handleSubmit = async(event) => {
        event.preventDefault();
      const form = new FormData(event.currentTarget);

      let data = {
        userName:form.get('loginId'),
        password:form.get("password")
      }
      let number = form.get("loginId")


    if (!number.startsWith("+91")) {
        // If it doesn't, prepend "+91" to it
        number = "+91" + number;
    }
    let res = await loginUserPhone(number,data.password)
      if(!res.success){
        notifyFailed(res.message)
        return
      }
      dispatch(loginDetailSave({userName:res.data.profile.name.userName,password:data.password,...res.data.profile}))
      dispatch(houseDataEmpty([]))
      dispatch(emptyTodayLogs({}))
      dispatch(emptySchedule([]))
      dispatch(emptyProperty({}))
      dispatch(emptyAutoCut([]))
       navigate('/dashboard')
    };
  return (
    <Container style={{display:'flex',justifyContent:"center",height:"100vh",alignItems:"center"}}>
        <div style={{width:"25%",boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",padding:"2.5%",borderRadius:"12px"}}>
     <Toaster/>
       <div>
        <img 
          style={{height:"50px",marginBottom:"10px"}}
          src={process.env.PUBLIC_URL+"./assets/Img/Logo.png"}/>
       </div>
       <form onSubmit={handleSubmit}>

       <Form.Group as={Col}  >
          <Form.Label>Login Id</Form.Label>
          <Form.Control 
            type="text" 
            placeholder="Login ID" 
            required 
            name="loginId"
            autoComplete='loginId'
          />
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Label>Password</Form.Label>
          <Form.Control 
            type="password" 
            required 
            name="password"
            autoComplete='current-password'
          />
        </Form.Group>
        <Button type='submit' className='mt-2'>login</Button>
       </form>
       
        </div>
    </Container>
  )
}
